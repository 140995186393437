import React, {Fragment} from 'react'

import Block from '../adapters/helpers/Block';
import PropTypes from 'prop-types'

import Meta from '../components/Meta/Meta';

class ErrorPage extends React.Component {

  static async getInitialProps() {
    
    const baseProps = await this.initPage();
    return {
      ...baseProps
    }
  }

  static async initPage() {
    const errorPageCode = 'errors-404';
    const { default: pageDao } = await import('Model/dao/PageDao');
    let page = await pageDao.getPageByCode(errorPageCode);

    return {'page' : page};
  }

  constructor(props) {
    super(props);
  }

  /**
   * Rendering of the page. This always render the page using a common layout.
   *
   * Override the render method to do otherwise.
   *
   * @returns {*}
   */
  render() {
    let page = this.getPage();
    const pagePath = page.slug.fields.slug;

    return (
      <Fragment>
        <Meta metadata={this.getMetadata()} pagePath={pagePath} />
        <div className="ob-wrapper">
            {this.renderBody()}
        </div>
      </Fragment>
    )
  }

  getPage() {
    return this.props.page.fields;
  }

  getMetadata() {
    return this.props.page.fields.metadata.fields
  }

  /**
   * By default, the page will render the modules of the page.
   * Can be overridden on the child class to do specific rendering.
   * @returns {*|void}
   */
  renderBody() {
    return new Block(this.props.page).renderChildBlocks();
  }
}

ErrorPage.propTypes = {
  page: PropTypes.object,
};

export default ErrorPage
